/* Tooltip css */
.tooltip-arrow::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 33%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
